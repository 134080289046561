import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { CustomHeader } from '../../types/distribution';

interface CustomHeaderInfoCardProps {
    customHeader: CustomHeader
}

const CustomHeadersInfoCard = ({ customHeader }: CustomHeaderInfoCardProps) => {
    return (
        <Row>
            <Col sm={6} md={6} xxl={4}>
                <span className="spanBold">Custom Header Name: </span> {customHeader.name}
            </Col>
            <Col sm={6} md={6} xxl={4}>
                <span className="spanBold">Custom Header Value: </span>{customHeader.value}
            </Col>
            <Col sm={0} md={0} xxl={4} />
        </Row>
    );
};

export default CustomHeadersInfoCard;
