import fetch from 'node-fetch';

const deleteBehavior = async (token: string, distributionName: string, originId: string, behaviorId: string) => {
    const response = await fetch(`${process.env.REACT_APP_ADN_MANAGEMENT_BASE_URL}/v1/distributions/${distributionName}/origins/${originId}/behaviors/${behaviorId}`, {
        method: 'DELETE',
        headers: {
            Authorization: `Bearer ${token}`
        }
    });

    if (!response.ok) {
        throw new Error(`Failed to delete behavior. Received status code ${response.status}`);
    }
};

export default deleteBehavior;
