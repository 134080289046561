import React, { useState, useReducer, createContext, useEffect, useMemo, ReactNode, FunctionComponent } from 'react';
import _ from 'lodash';
import validateDistribution from '../../util/api/validateDistribution';
import validateDomains from '../../util/api/validateDomains';
import { useAuthentication } from '../../authentication/Authentication';
import emptyStringToUndefined from '../../util/emptyStringToUndefined';
import getDistributionNames from '../../util/api/getDistributionNames';
import getDistribution from '../../util/api/getDistribution';
import getDistributionStatus from '../../util/api/getDistributionStatus';
import { Distribution } from '../../types/distribution';
import { PermissionType } from '../../types/permissions';
import { ComponentError } from '../../types/misc';

const DistributionContext = createContext<DistributionProviderValues | undefined>(undefined);
export const enum DistributionActionTypes {
    UPDATE = 'update',
    DELETE = 'delete',
    REFRESH = 'refresh'
}

interface Actions {
    UPDATE: string
    DELETE: string
    REFRESH: string
}

const possibleActions: Actions = {
    UPDATE: 'update',
    DELETE: 'delete',
    REFRESH: 'refresh'
};
const ACTIONS = Object.freeze(possibleActions);

interface DistributionDeleteAction {
    type: DistributionActionTypes
    path: string
    index?: number
    id?: string
}

interface DistributionUpdateAction {
    type: DistributionActionTypes
    path: string
    value: any
}

interface DistributionRefreshAction {
    type: DistributionActionTypes
    value: any
}

interface DistributionProviderProps {
    children: ReactNode
    defaultState: Distribution
    initialPermissions: PermissionType[]
    defaultIsPending: boolean
}

interface DistributionProviderValues {
    distribution: Distribution
    dispatch: (action: DistributionDeleteAction | DistributionUpdateAction | DistributionRefreshAction) => void
    ACTIONS: Actions
    hasPendingChanges: boolean
    errors: ComponentError[]
    checkDistribution: () => Promise<boolean>
    submitToGithub: Function
    refreshDistribution: Function
    didDistributionChange: Function
    distributionIsReadOnly: Function
    userPermission: PermissionType[]
    setUserPermission: Function
    getDistributionName: Function
}

// we have three actions we can take
// UPDATE, DELETE, ADD
const distributionReducer = (state: Distribution, action: DistributionDeleteAction | DistributionUpdateAction | DistributionRefreshAction) => {
    const newState = JSON.parse(JSON.stringify(state));

    switch (action.type) {
        case (DistributionActionTypes.UPDATE): {
            const updateAction = action as DistributionUpdateAction;

            const result = _.set(newState, updateAction.path, updateAction.value);
            return result;
        }
        case (DistributionActionTypes.DELETE): {
            const deleteAction = action as DistributionDeleteAction;
            const array = _.get(newState, deleteAction.path);
            if (!Array.isArray(array)) {
                throw new Error('Action DELETE can only be on an array');
            }

            const newArray = array.filter((x, index) => {
                if (deleteAction.index !== undefined) {
                    return index !== deleteAction.index;
                }

                return x.id !== deleteAction.id;
            });

            const result = _.set(newState, deleteAction.path, newArray);

            return result;
        }
        case (DistributionActionTypes.REFRESH): {
            return (action as DistributionRefreshAction).value;
        }
        default: {
            throw new Error(`Invalid distribution action ${action.type}`);
        }
    }
};

const doesDistributionNameExist = async (token: string, { productFamily, environment }: Distribution) => {
    const distributionNames = await getDistributionNames(token);

    const distributionName = `${productFamily}_${environment}`;
    return distributionNames.includes(distributionName);
};

const getNewDomains = (initialDistribution: Distribution, currentDistribution: Distribution) => {
    const currentDomains = currentDistribution.domains || [];
    const initialDomainsAsDomainNames = (initialDistribution.domains || [])
        .map(({ domainName }) => domainName);

    return currentDomains.filter(({ domainName }) => !initialDomainsAsDomainNames.includes(domainName));
};

const DistributionProvider: FunctionComponent<DistributionProviderProps> = ({ children, defaultState, initialPermissions, defaultIsPending }) => {
    const [errors, setErrors] = useState<ComponentError[]>([]);
    const [userPermission, setUserPermission] = useState(initialPermissions);
    const [initialDistributionState, setInitialDistributionState] = useState(defaultState);
    const [distribution, dispatch] = useReducer(distributionReducer, defaultState);
    const [hasPendingChanges, setHasPendingChanges] = useState(false);
    const { token } = useAuthentication();

    useEffect(() => {
        setHasPendingChanges(defaultIsPending);
    }, [defaultIsPending]);

    const didDistributionChange = () => {
        return JSON.stringify(initialDistributionState) !== JSON.stringify(distribution);
    };

    const getDistributionName = () => {
        const { distributionName } = distribution;

        return distributionName || `${distribution.productFamily}_${distribution.environment}`;
    };

    const distributionIsReadOnly = () => {
        // if distribution name does not exist this is a new distribution
        if (!distribution.distributionName) {
            return false;
        }

        return !userPermission || !userPermission.includes(PermissionType.write);
    };

    const checkDistribution = async () => {
        const cleanDistribution = emptyStringToUndefined(distribution);
        const newDomains = getNewDomains(initialDistributionState, cleanDistribution);

        const [validateDistributionResult, validateDomainsResult] = await Promise.all([
            validateDistribution(token, cleanDistribution),
            validateDomains(token, newDomains)
        ]);

        const allErrors = [...validateDistributionResult.errors, ...validateDomainsResult.errors];

        if (!distribution.distributionName && await doesDistributionNameExist(token, distribution)) {
            const error: ComponentError = {
                path: 'naming',
                // eslint-disable-next-line max-len
                message: `Naming Conflict: "${distribution.productFamily}_${distribution.environment}". There is already a deployed distribution or an existing set of changes for product family: "${distribution.productFamily}" on environment: "${distribution.environment}".`
            };
            allErrors.push(error);
        }

        setErrors(allErrors);
        if (allErrors.length > 0) {
            return false;
        }

        return true;
    };

    const submitToGithub = async () => {
        setErrors([]);
        const cleanDistribution = emptyStringToUndefined(distribution);

        const distributionName = distribution.distributionName;
        const uri = distributionName ? `/distributions/${distributionName}` : '/distributions';
        const method = distributionName ? 'PATCH' : 'POST';

        const response = await fetch(`${process.env.REACT_APP_ADN_MANAGEMENT_BASE_URL}/v1${uri}`, {
            method,
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json ',
                Accept: 'application/json'
            },
            body: JSON.stringify(cleanDistribution)
        });

        if (!response.ok) {
            if (response.status === 400) {
                const result = await response.json();

                if (result.message && !result.validation) {
                    setErrors([{
                        path: '',
                        message: result.message
                    }]);
                } else {
                    setErrors(result.validation.errors);
                }
            } else {
                setErrors([{
                    path: '',
                    message: 'Failed to submit distribution for unknown reason'
                }]);
            }

            return false;
        }

        const updatedDistribution = await response.json() as Distribution;
        const refreshAction = {
            type: DistributionActionTypes.REFRESH,
            value: updatedDistribution
        };
        dispatch(refreshAction);
        setInitialDistributionState(updatedDistribution);
        setHasPendingChanges(true);
        return true;
    };

    const refreshDistribution = async () => {
        if (!distribution.distributionName) {
            throw new Error('Distribution cannot be refreshed because it does not exist yet');
        }

        const currentStatus = await getDistributionStatus(token, distribution.distributionName);
        const isPending = currentStatus !== 'DEPLOYED';

        const updatedDistribution = await getDistribution(token, distribution.distributionName, isPending);
        if (!updatedDistribution) {
            throw new Error('Unable to find distribution!!');
        }

        setHasPendingChanges(isPending);
        setInitialDistributionState(updatedDistribution);
        dispatch({
            type: DistributionActionTypes.REFRESH,
            value: updatedDistribution
        });
    };

    const value: DistributionProviderValues = useMemo(() => {
        return {
            distribution,
            dispatch,
            ACTIONS,
            hasPendingChanges,
            errors,
            checkDistribution,
            submitToGithub,
            refreshDistribution,
            didDistributionChange,
            distributionIsReadOnly,
            userPermission,
            setUserPermission,
            getDistributionName
        };
    }, [distribution, hasPendingChanges, errors, userPermission]);

    return (
        <DistributionContext.Provider value={value}>
            {children}
        </DistributionContext.Provider>
    );
};

const useDistribution = () => {
    const context = React.useContext(DistributionContext);

    if (context === undefined) {
        throw new Error('useDistribution can only be used within a distributionContext');
    }

    return context;
};

export type {
    DistributionDeleteAction,
    DistributionUpdateAction,
    DistributionRefreshAction
};

export {
    DistributionProvider,
    useDistribution
};
