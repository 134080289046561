const emptyStringToUndefined = (object) => {
    const keys = Object.keys(object);

    return keys.reduce((accumulator, key) => {
        const value = object[key];

        if (Array.isArray(value)) {
            const cleanArray = value
                .map((entry) => {
                    if (typeof entry === 'object') {
                        return emptyStringToUndefined(entry);
                    }

                    return entry;
                })
                .filter((entry) => entry !== '' && Object.keys(entry).length > 0);

            return {
                ...accumulator,
                [key]: cleanArray
            };
        }

        if (typeof value === 'object') {
            return {
                ...accumulator,
                [key]: {
                    ...emptyStringToUndefined(value)
                }
            };
        }

        if (typeof value === 'string') {
            if (value.trim() === '' || key === 'uiId') {
                return {
                    ...accumulator
                };
            }
        }

        return {
            ...accumulator,
            [key]: value
        };
    }, {});
};

export default emptyStringToUndefined;
