import React from 'react';
import { Breadcrumb } from 'react-bootstrap';
import { useDistribution } from './DistributionProvider';
import { PageHash, PageTypes } from '../../types/form';
import { Distribution } from '../../types/distribution';

interface FormNavigationProps {
    pages: PageHash
    currentPage: PageTypes
    changePage: (page: PageTypes) => void
}

const originRequiredPages = [PageTypes.domains, PageTypes.permissions, PageTypes.summary];

const hasOriginsCheck = ({ origins }: Distribution) => {
    if (origins.length === 0) {
        return false;
    }

    return origins.every(({ behaviors }) => behaviors.length > 0);
};

const FormNavigation = ({ pages, currentPage, changePage }: FormNavigationProps) => {
    const { distribution, checkDistribution, refreshDistribution } = useDistribution();

    const buildPageLinks = () => {
        return Object.keys(pages).map((x: string) => {
            const key = x as PageTypes;

            /**
             * This is a check to see if the distribution has at least one origin,
             * with at least one behavior defined for each origin.
             * If the user does not, the user will not be able to move past the origins page.
             */
            const hasOrigins = hasOriginsCheck(distribution);

            // if we have a new distribution, form navigation is disabled. The user must complete the first page before moving around
            const isCurrentNavigationComponent = key === currentPage || !distribution.distributionName || (!hasOrigins && originRequiredPages.includes(key));

            const handleOnClick = async () => {
                if (isCurrentNavigationComponent) {
                    return;
                }

                const success = await checkDistribution();

                // if no errors on the distribution, move to the next page
                if (success) {
                    refreshDistribution();
                    changePage(key);
                }
            };

            if (!distribution.distributionName && key === currentPage) {
                return (
                    <Breadcrumb.Item key={key} active={isCurrentNavigationComponent} onClick={handleOnClick} className="adnBreadCrumbSelected">{pages[key]?.pageName}</Breadcrumb.Item>
                );
            }

            return (
                <Breadcrumb.Item key={key} active={isCurrentNavigationComponent} onClick={handleOnClick} className="adnBreadCrumb">{pages[key]?.pageName}</Breadcrumb.Item>
            );
        });
    };

    return (
        <>
            <h3>{pages[currentPage]?.pageTitle}</h3>
            <Breadcrumb>
                {buildPageLinks()}
            </Breadcrumb>
            <hr />
        </>
    );
};

export default FormNavigation;
