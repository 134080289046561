import fetch from 'node-fetch';

const deleteDomain = async (token: string, distributionName: string, domainId: string) => {
    const response = await fetch(`${process.env.REACT_APP_ADN_MANAGEMENT_BASE_URL}/v1/distributions/${distributionName}/domains/${domainId}`, {
        method: 'DELETE',
        headers: {
            Authorization: `Bearer ${token}`
        }
    });

    if (!response.ok) {
        throw new Error(`Failed to delete domain. Received status code ${response.status}`);
    }
};

export default deleteDomain;
