interface FormParams {
    distributionName: undefined | string
    page: PageTypes
}

export const enum PageTypes {
    main = 'main',
    origins = 'origins',
    domains = 'domains',
    summary = 'summary',
    permissions = 'permissions'
}

// The pageTitle is displayed above the breadcrumb, while the pageName is the name of the page in the breadcrumb
interface PageInfo {
    pageTitle: string
    pageName: string
}

interface PageHash {
    [PageTypes.main]?: PageInfo
    [PageTypes.origins]?: PageInfo
    [PageTypes.domains]?: PageInfo
    [PageTypes.summary]: PageInfo
    [PageTypes.permissions]: PageInfo
}

export type {
    PageHash,
    FormParams,
    PageInfo
};
