import React from 'react';
import { Row, Form, Col } from 'react-bootstrap';

import ErrorComponent from '../../util/Error';
import { OriginMetaDataApigee } from '../../types/distribution';
import { ComponentError } from '../../types/misc';

interface ApigeeMetaDataProps {
    originMetaData: OriginMetaDataApigee
    setOriginMetaData: Function
    path: String
    errors: ComponentError[]
}

const ApigeeMetaData = ({ originMetaData, setOriginMetaData, path, errors }: ApigeeMetaDataProps) => {
    const organizationEnvList = {
        '': ['', 'dev', 'sat', 'test', 'uat', 'trinternal', 'trexternal'],
        'tr-api-cloud-qa': ['', 'dev', 'sat', 'test'],
        'tr-api-cloud': ['', 'uat', 'trinternal', 'trexternal']
    };

    const virtualHostList = [
        '',
        'clear',
        'internal',
        'onesourcetax',
        'onvio',
        'secure',
        'gosystem'
    ];

    const updateOriginVerify = (event: React.ChangeEvent<any>) => {
        setOriginMetaData({
            ...originMetaData,
            [event.target.id]: event.target.value
        });
    };

    const generateOptions = (mode: string) => {
        let list: Array<string> = [];
        let value: string = '';
        if (mode === 'virtualHost') {
            list = virtualHostList;
            value = originMetaData.virtualHost;
        } else if (mode === 'organization') {
            list = Object.keys(organizationEnvList);
            value = originMetaData.organization;
        } else if (mode === 'environment') {
            if (originMetaData.organization === 'tr-api-cloud-qa') {
                list = organizationEnvList['tr-api-cloud-qa'];
            } else if (originMetaData.organization === 'tr-api-cloud') {
                list = organizationEnvList['tr-api-cloud'];
            } else {
                list = organizationEnvList[''];
            }
            value = originMetaData.environment;
        }

        return (
            <Form.Select id={mode} value={value} onChange={updateOriginVerify}>
                {list.map((key: string) => {
                    return <option key={key} value={key}>{key}</option>;
                })}
            </Form.Select>
        );
    };

    return (
        <>
            <Row>
                <Form.Group as={Col}>
                    <Form.Label>
                        Organization
                        <span className="required-field"> *</span>
                    </Form.Label>
                    {generateOptions('organization')}
                    <ErrorComponent path={`${path}.organization`} errors={errors} />
                </Form.Group>
                <Form.Group as={Col}>
                    <Form.Label>
                        Environment
                        <span className="required-field"> *</span>
                    </Form.Label>
                    {generateOptions('environment')}
                    <ErrorComponent path={`${path}.environment`} errors={errors} />
                </Form.Group>
            </Row>
            <Row>
                <Form.Group as={Col}>
                    <Form.Label>
                        Virtual Host
                        <span className="required-field"> *</span>
                    </Form.Label>
                    {generateOptions('virtualHost')}
                    <ErrorComponent path={`${path}.virtualHost`} errors={errors} />
                </Form.Group>
                <Col />
            </Row>
        </>
    );
};

export default ApigeeMetaData;
