import React, { useState, ReactNode } from 'react';
import { Spinner, Button } from 'react-bootstrap';

interface LoadingButtonProps {
    onClick: Function
    className?: string
    style?: React.CSSProperties
    children: ReactNode
    disabled?: boolean
    variant?: string
}

const LoadingButton = ({ onClick, className, style, children, disabled, variant }: LoadingButtonProps) => {
    const [isLoading, setIsLoading] = useState(false);

    const handleOnClick = async () => {
        if (isLoading) {
            // no-op the button is disabled
            return;
        }
        setIsLoading(true);
        await onClick();
        setIsLoading(false);
    };

    return (
        <Button onClick={handleOnClick} variant={variant} className={className} style={style} disabled={disabled || isLoading}>
            {children}
            {isLoading ? <Spinner style={{ marginLeft: '5px' }} animation="border" size="sm" /> : undefined}
        </Button>
    );
};

LoadingButton.defaultProps = {
    className: '',
    variant: 'primary',
    style: undefined,
    disabled: false
};

export default LoadingButton;
