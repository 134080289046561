import { nanoid } from 'nanoid';
import React, { useState, useEffect } from 'react';
import { Row, Col, Form, FormGroup, Button } from 'react-bootstrap';
import { FiEdit } from 'react-icons/fi';
import { cloneDeep } from 'lodash';

import { useAuthentication } from '../../authentication/Authentication';
import { DistributionActionTypes, DistributionDeleteAction, useDistribution } from '../../distributions/form/DistributionProvider';
import { Behavior, Origin } from '../../types/distribution';
import { ComponentError } from '../../types/misc';
import deleteBehavior from '../../util/api/deleteBehavior';
import putBehavior from '../../util/api/putBehavior';
import ErrorComponent from '../../util/Error';
import LoadingButton from '../../util/LoadingButton';
import styles from '../OriginCard.module.css';

interface BehaviorInfoCardProps {
    behavior: Behavior
    origin: Origin
    path: string
    onEditing: (id: string, editing: boolean) => void
}

interface ResponseHeadersPolicies {
    id: string,
    name: string
}

const BehaviorCard = ({ behavior, origin, path, onEditing }: BehaviorInfoCardProps) => {
    const { dispatch, getDistributionName } = useDistribution();
    const { token } = useAuthentication();
    const [localErrors, setLocalErrors] = useState<ComponentError[]>([]);
    const [edit, setEdit] = useState(false);
    const [localBehavior, setLocalBehavior] = useState<Behavior>(cloneDeep(behavior));

    const forwardedHeaders = localBehavior.forwardedHeaders || [];
    const [forwardAllHeaders, setForwardAllHeaders] = useState(forwardedHeaders.length === 1 && forwardedHeaders[0] === '*');

    const [responseHeadersPolicyId] = useState<string>('');
    const [responseHeadersPolicyList, setResponseHeadersPolicyList] = useState<Array<ResponseHeadersPolicies>>([]);

    useEffect(() => {
        const fetchPolicies = async () => {
            const response = await fetch(`${process.env.REACT_APP_ADN_MANAGEMENT_BASE_URL}/v1/responseHeadersPolicies`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${token}`,
                    Accept: 'application/json '
                }
            });
            const policyList = await response.json();
            setResponseHeadersPolicyList(policyList);
        };

        fetchPolicies().catch(console.error);
    }, [responseHeadersPolicyId]);

    const enableEdit = () => {
        setEdit(true);
        if (localBehavior.id) {
            onEditing(localBehavior.id, true);
        }
    };

    const handleOnChange = (event: React.ChangeEvent<any>) => {
        const field = event.target.id;
        const value = event.target.value;

        setLocalBehavior({
            ...localBehavior,
            [field]: value
        });
    };

    const handleChangeForwardedHeader = (event: React.ChangeEvent<any>) => {
        const updatedHeaders = localBehavior.forwardedHeaders ? cloneDeep(localBehavior.forwardedHeaders) : [];
        updatedHeaders[event.target.id] = event.target.value;
        setLocalBehavior({
            ...localBehavior,
            forwardedHeaders: updatedHeaders
        });
    };

    const buildBehaviorPath = () => {
        if (localBehavior.isDefaultBehavior) {
            return (
                <Form as={Row}>
                    <FormGroup as={Col}>
                        <Form.Label>Behavior Path</Form.Label>
                        <Form.Control type="text" placeholder="Behavior Path" id="uriPattern" disabled value="*" />
                        <ErrorComponent path="uriPattern" errors={localErrors} />
                    </FormGroup>
                    <FormGroup as={Col}>
                        <Form.Label>Asset Insight ID</Form.Label>
                        <Form.Control onChange={handleOnChange} type="text" placeholder="Asset Insight ID" id="assetInsightId" disabled={!edit} value={localBehavior.assetInsightId} />
                        <ErrorComponent path="assetInsightId" errors={localErrors} />
                    </FormGroup>
                </Form>
            );
        }

        return (
            <Form as={Row}>
                <FormGroup as={Col}>
                    <Form.Label>Behavior Path</Form.Label>
                    <Form.Control onChange={handleOnChange} type="text" placeholder="Behavior Path" id="uriPattern" disabled={!edit} value={localBehavior.uriPattern} />
                    <ErrorComponent path="uriPattern" errors={localErrors} />
                </FormGroup>
                <FormGroup as={Col}>
                    <Form.Label>Asset Insight ID</Form.Label>
                    <Form.Control onChange={handleOnChange} type="text" placeholder="Asset Insight ID" id="assetInsightId" disabled={!edit} value={localBehavior.assetInsightId} />
                    <ErrorComponent path="assetInsightId" errors={localErrors} />
                </FormGroup>
            </Form>
        );
    };

    const updateBehaviorEvent = async () => {
        setLocalErrors([]);
        const response = await putBehavior(token, getDistributionName(), origin.id as string, localBehavior);

        if (!response.success) {
            setLocalErrors(response.errors);
        } else {
            const createdBehavior = response.behavior;
            dispatch({
                type: DistributionActionTypes.UPDATE,
                path,
                value: createdBehavior
            });
            if (localBehavior.id) {
                onEditing(localBehavior.id, false);
            }
            setEdit(false);
        }
    };

    const deleteBehaviorEvent = async () => {
        try {
            await deleteBehavior(token, getDistributionName(), origin.id as string, localBehavior.id as string);
            const paths = path.split('.', 2);
            const deleteAction: DistributionDeleteAction = {
                type: DistributionActionTypes.DELETE,
                path: `${paths[0]}.behaviors`,
                id: localBehavior.id
            };

            dispatch(deleteAction);
        } catch (err) {
            const deleteError = {
                path: 'behaviorPath',
                message: 'Failed to delete Behavior',
                uiId: nanoid()
            } as ComponentError;
            setLocalErrors([deleteError]);
        }
    };

    const addForwardedHeader = () => {
        const updatedHeaders = localBehavior.forwardedHeaders ? cloneDeep(localBehavior.forwardedHeaders) : [];
        updatedHeaders.push('');
        setLocalBehavior({
            ...localBehavior,
            forwardedHeaders: updatedHeaders
        });
    };

    const deleteForwardedHeader = (index: number) => {
        const initialValue: string[] = [];
        if (localBehavior.forwardedHeaders) {
            const updatedHeaders = localBehavior.forwardedHeaders.reduce((accumulatedHeaders, header, i) => {
                if (i !== index) {
                    accumulatedHeaders.push(header);
                }
                return accumulatedHeaders;
            }, initialValue);
            setLocalBehavior({
                ...localBehavior,
                forwardedHeaders: updatedHeaders
            });
        }
    };

    const changeForwardAllHeadersStatus = () => {
        if (forwardAllHeaders) {
            setForwardAllHeaders(false);
            setLocalBehavior({
                ...localBehavior,
                forwardedHeaders: []
            });
        } else {
            setForwardAllHeaders(true);
            setLocalBehavior({
                ...localBehavior,
                forwardedHeaders: ['*']
            });
        }
        dispatch({
            type: DistributionActionTypes.UPDATE,
            path,
            value: localBehavior
        });
    };

    const changeResponseHeadersPolicy = (event: React.ChangeEvent<any>) => {
        if (event.target.value === 'None') {
            setLocalBehavior({
                ...localBehavior,
                responseHeadersPolicyId: undefined
            });
        } else {
            setLocalBehavior({
                ...localBehavior,
                responseHeadersPolicyId: event.target.value
            });
        }
    };

    const buildResponseHeadersPolicy = () => {
        if (edit) {
            return (
                <>
                    <span>Response Headers Policy ID: </span>
                    <Form.Select id="responseHeadersPolicy" value={localBehavior.responseHeadersPolicyId} onChange={changeResponseHeadersPolicy}>
                        <option key="none">None</option>
                        {responseHeadersPolicyList.map((policy:ResponseHeadersPolicies) => {
                            return <option key={policy.id} value={policy.id}>{`${policy.name} | ${policy.id}`}</option>;
                        })}
                    </Form.Select>
                    <ErrorComponent path="behavior.responseHeadersPolicyId" errors={localErrors} />
                </>
            );
        }

        return (
            <>
                <span>Response Headers Policy ID: </span>
                <span>{localBehavior.responseHeadersPolicyId}</span>
            </>
        );
    };

    const buildForwardedHeaders = () => {
        if (localBehavior.forwardedHeaders === undefined) {
            return <span>Forwarded Headers: </span>;
        }

        const headers = localBehavior.forwardedHeaders.map((header, i) => {
            if (edit) {
                return (
                    <Row>
                        <Col>
                            <Form.Control onChange={handleChangeForwardedHeader} type="text" placeholder="Forwarded Header Name" id={`${i}`} value={header} disabled={forwardAllHeaders} />
                        </Col>
                        <Col hidden={forwardAllHeaders}>
                            <LoadingButton variant="warning" onClick={() => deleteForwardedHeader(i)}>Delete</LoadingButton>
                        </Col>
                    </Row>
                );
            }
            return (
                <Row>
                    <Col>
                        {header}
                    </Col>
                </Row>
            );
        });

        if (edit) {
            return (
                <>
                    <Form.Check
                        type="switch"
                        id={`forwardAllHeadersSwitch-${behavior.uiId}`}
                        label="Forward All Headers"
                        onChange={changeForwardAllHeadersStatus}
                        checked={forwardAllHeaders}
                    />
                    <span>Forwarded Headers: </span>
                    <Col>
                        {headers}
                        <Button onClick={addForwardedHeader} className={styles.addEditButton} variant="link" hidden={forwardAllHeaders}>
                            Add Forwarded Header
                        </Button>
                    </Col>
                </>
            );
        }

        return (
            <>
                <span>Forwarded Headers: </span>
                <Col>
                    {headers}
                </Col>
            </>
        );
    };

    const buildBehaviorOptions = () => {
        const buttonKey = `${localBehavior.id}-remove-button`;
        if (edit) {
            return (
                <LoadingButton key={buttonKey} variant="outline-primary" className="inline-form-button" onClick={updateBehaviorEvent}>
                    Update Behavior
                </LoadingButton>
            );
        }

        if (localBehavior.isDefaultBehavior) {
            return (
                <FiEdit size={38} style={{ marginRight: '5px' }} onClick={enableEdit} className="inline-form-button" />
            );
        }

        return (
            <>
                <FiEdit size={38} style={{ marginRight: '5px' }} onClick={enableEdit} className="inline-form-button" />
                <LoadingButton key={buttonKey} variant="outline-primary" className="inline-form-button" onClick={deleteBehaviorEvent}>
                    Remove
                </LoadingButton>
            </>
        );
    };

    return (
        <Form className={styles.behaviorPadding}>
            <Row>
                <Col>
                    {buildBehaviorPath()}
                </Col>
                <Col md="auto">
                    {buildBehaviorOptions()}
                </Col>
            </Row>
            <Row>
                {buildForwardedHeaders()}
            </Row>
            <Row>
                {buildResponseHeadersPolicy()}
            </Row>
        </Form>
    );
};

export default BehaviorCard;
