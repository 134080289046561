import React, { useState } from 'react';
import { Row, Form, Col, Button, Modal } from 'react-bootstrap';
import { cloneDeep } from 'lodash';
import { FiEdit } from 'react-icons/fi';
import { nanoid } from 'nanoid';

import ApigeeMetaData from './originVerifyMetaData/ApigeeMetaData';
import AwsMetaData from './originVerifyMetaData/AwsMetaData';
import ErrorComponent from '../util/Error';
import { DistributionActionTypes, useDistribution } from '../distributions/form/DistributionProvider';
import { useAuthentication } from '../authentication/Authentication';
import { OriginVerify, OriginTypes, OriginMetaDataAws, OriginMetaDataApigee } from '../types/distribution';
import saveOriginVerify from '../util/api/saveOriginVerify';
import { ComponentError } from '../types/misc';
import styles from './OriginCard.module.css';
import LoadingButton from '../util/LoadingButton';
import TopLevelErrors from './TopLevelErrors';
import deleteOriginVerify from '../util/api/deleteOriginVerify';

interface OriginVerifyProps {
    originVerify: OriginVerify
    originId: string
    exists: boolean
    path: string
}

const OriginVerifyModal = ({ originVerify, exists, originId, path }: OriginVerifyProps) => {
    const { dispatch, getDistributionName } = useDistribution();
    const { token } = useAuthentication();

    const [localOriginVerify, setLocalOriginVerify] = useState<OriginVerify>(cloneDeep(originVerify));
    const [localErrors, setLocalErrors] = useState<ComponentError[]>([]);

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleClosedError = (uiId: string) => {
        setLocalErrors(localErrors.filter((x) => x.uiId !== uiId));
    };

    const setOriginVerifyMetaData = (event: OriginMetaDataApigee | OriginMetaDataAws) => {
        setLocalOriginVerify({
            ...localOriginVerify,
            originMetaData: {
                ...event
            }
        });
    };

    const getOriginVerifyMetaData = () => {
        if (localOriginVerify.originType === OriginTypes.apigee) {
            const apigeeMetaData: OriginMetaDataApigee = localOriginVerify.originMetaData as OriginMetaDataApigee;
            return (<ApigeeMetaData originMetaData={apigeeMetaData} setOriginMetaData={setOriginVerifyMetaData} path="originMetaData" errors={localErrors} />);
        }

        if (localOriginVerify.originType === OriginTypes.aws) {
            const awsMetaData: OriginMetaDataAws = localOriginVerify.originMetaData as OriginMetaDataAws;
            return (<AwsMetaData originMetaData={awsMetaData} setOriginMetaData={setOriginVerifyMetaData} path="originMetaData" errors={localErrors} />);
        }

        return undefined;
    };

    const showSecretName = () => {
        return (
            <>
                <Form.Group as={Col}>
                    <Form.Label>
                        Secret Name
                    </Form.Label>
                    <Form.Control required id="secretName" placeholder="Secret Name" value={localOriginVerify.secretName} readOnly />
                    <ErrorComponent path="secretName" errors={localErrors} />
                </Form.Group>
                <Col />
            </>
        );
    };

    const changeOriginVerifyType = (event: React.ChangeEvent<any>) => {
        if (event.target.value === 'AWS') {
            setLocalOriginVerify({
                originType: OriginTypes.aws,
                originMetaData: {
                    accountId: '',
                    region: 'us-east-1'
                },
                secretName: undefined
            });
        }

        if (event.target.value === 'apigee') {
            setLocalOriginVerify({
                originType: OriginTypes.apigee,
                originMetaData: {
                    environment: '',
                    organization: '',
                    virtualHost: ''
                },
                secretName: undefined
            });
        }
    };

    const saveButton = async () => {
        setLocalErrors([]);
        const response = await saveOriginVerify(token, getDistributionName(), originId, localOriginVerify);
        if (!response.success) {
            setLocalErrors(response.errors);
        } else {
            dispatch({
                type: DistributionActionTypes.UPDATE,
                path,
                value: localOriginVerify
            });
            handleClose();
        }
    };

    const deleteButton = async () => {
        try {
            await deleteOriginVerify(token, getDistributionName(), originId as string);
            dispatch({
                type: DistributionActionTypes.UPDATE,
                path,
                value: undefined
            });
            handleClose();
        } catch (err) {
            const deleteError = {
                path: '',
                message: 'Failed to delete OriginVerify',
                uiId: nanoid()
            } as ComponentError;
            setLocalErrors([deleteError]);
        }
    };

    return (
        <>
            <div className={styles.addEditDiv}>
                <Button className={styles.addEditButton} variant="link" onClick={handleShow}>
                    <FiEdit className={styles.addEditIcon} />
                    Origin Verify
                </Button>
            </div>

            <Modal size="lg" show={show} onHide={handleClose} scrollable>
                <Modal.Header closeButton>
                    <Modal.Title>{localOriginVerify.originMetaData ? 'Edit' : 'Add'} Origin Verify</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <span className="documentation">All incoming traffic comes through this distribution.</span>
                    </Row>
                    <TopLevelErrors errors={localErrors} onCloseError={handleClosedError} />
                    <Row>
                        <Form.Group as={Col}>
                            <Form.Label>
                                Origin Type
                                <span className="required-field"> *</span>
                            </Form.Label>
                            <Form.Select required id="originType" onChange={changeOriginVerifyType} value={localOriginVerify.originType}>
                                <option value="AWS">AWS</option>
                                <option value="apigee">apigee</option>
                            </Form.Select>
                        </Form.Group>
                        <Col />
                    </Row>
                    {getOriginVerifyMetaData()}
                    {localOriginVerify.secretName ? showSecretName() : undefined}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    {exists ? (
                        <LoadingButton variant="warning" onClick={deleteButton}>
                            Delete
                        </LoadingButton>
                    ) : undefined}
                    <LoadingButton variant="primary" onClick={saveButton}>
                        Save Changes
                    </LoadingButton>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default OriginVerifyModal;
