import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { Behavior } from '../../types/distribution';
import styles from './InfoCards.module.css';

interface BehaviorInfoCardProps {
    behavior: Behavior
}

const BehaviorInfoCard = ({ behavior }: BehaviorInfoCardProps) => {
    const buildBehaviorPath = () => {
        if (behavior.isDefaultBehavior) {
            return (
                <>
                    <span className="spanBold">Behavior Path: </span> *
                </>
            );
        }

        return (
            <>
                <span className="spanBold">Behavior Path: </span> {behavior.uriPattern}
            </>
        );
    };

    const buildForwardedHeaders = () => {
        if (behavior.forwardedHeaders === undefined) {
            return <span className="spanBold">Forwarded Headers: </span>;
        }

        if (behavior.forwardedHeaders.length === 1 && behavior.forwardedHeaders[0] === '*') {
            return (
                <>
                    <span className="spanBold">Forward All Headers: </span> *
                </>
            );
        }

        const headers = behavior.forwardedHeaders.map((header, i) => {
            if (i === 0) {
                return (
                    <span key={`${behavior.id}-${header}`}>{header}</span>
                );
            }

            return (
                <span key={`${behavior.id}-${header}`}>, {header}</span>
            );
        });

        return (
            <>
                <span className="spanBold">Forwarded Headers: </span> {headers}
            </>
        );
    };

    const buildResponseHeadersPolicyId = () => {
        if (behavior.forwardedHeaders === undefined) {
            return <span className="spanBold">Response Headers Policy Id: </span>;
        }

        return (
            <>
                <span className="spanBold">Response Headers Policy Id: </span> {behavior.responseHeadersPolicyId}
            </>
        );
    };

    return (
        <Container className={styles.behaviorPadding}>
            <Row>
                <Col>
                    {buildBehaviorPath()}
                </Col>
            </Row>
            <Row>
                <Col>
                    {buildForwardedHeaders()}
                </Col>
            </Row>
            <Row>
                <Col>
                    {buildResponseHeadersPolicyId()}
                </Col>
            </Row>
        </Container>
    );
};

export default BehaviorInfoCard;
