import React, { useState } from 'react';
import { Form, Col } from 'react-bootstrap';
import { cloneDeep } from 'lodash';
import { FiEdit } from 'react-icons/fi';
import LoadingButton from '../util/LoadingButton';
import ErrorComponent from '../util/Error';
import deleteDomain from '../util/api/deleteDomain';
import updateDomain from '../util/api/updateDomain';
import { DistributionActionTypes, useDistribution } from '../distributions/form/DistributionProvider';
import { Domain } from '../types/distribution';
import { useAuthentication } from '../authentication/Authentication';
import { ComponentError } from '../types/misc';

interface DomainProps {
    domain: Domain
    path: string,
    onError: Function
}

const DomainComponent = ({ domain, path, onError }: DomainProps) => {
    const [edit, setEdit] = useState(false);
    const [localDomain, setLocalDomain] = useState(cloneDeep(domain));
    const [localErrors, setLocalErrors] = useState<ComponentError[]>([]);
    const { dispatch, getDistributionName } = useDistribution();
    const { token } = useAuthentication();

    const handleDomainNameChange = (event: React.ChangeEvent<any>) => {
        setLocalDomain({
            ...localDomain,
            domainName: event.target.value
        });
    };

    const updateDomainEvent = async () => {
        setLocalErrors([]);

        try {
            const response = await updateDomain(token, getDistributionName(), localDomain);

            if (response.success) {
                const updatedDomain = response.domain as Domain;
                const updateAction = {
                    type: DistributionActionTypes.UPDATE,
                    path,
                    value: updatedDomain
                };

                dispatch(updateAction);
                setLocalDomain(updatedDomain);
                setEdit(false);
            } else {
                const topLevelErrors = response.errors.filter((x) => x.path === '');
                topLevelErrors.forEach(({ message }) => onError(message));
                setLocalErrors(response.errors);
            }
        } catch (err: any) {
            onError(err.message);
        }
    };

    const deleteDomainEvent = async () => {
        try {
            await deleteDomain(token, getDistributionName(), localDomain.id as string);

            const deleteAction = {
                type: DistributionActionTypes.DELETE,
                path: 'domains',
                id: localDomain.id
            };
            dispatch(deleteAction);
        } catch (err) {
            // send error up to parent component
            onError('Failed to delete the domain');
        }
    };

    const enableEdit = () => {
        setEdit(true);
    };

    const buildDomainOptions = () => {
        const buttonKey = `${domain.id}-update-remove-button`;
        if (edit) {
            return (
                <LoadingButton key={buttonKey} variant="outline-primary" className="inline-form-button" onClick={updateDomainEvent}>
                    Update Domain
                </LoadingButton>
            );
        }

        return (
            <>
                <FiEdit size={38} style={{ marginRight: '5px' }} onClick={enableEdit} className="inline-form-button" />
                <LoadingButton key={buttonKey} variant="outline-primary" className="inline-form-button" onClick={deleteDomainEvent}>
                    Remove Domain
                </LoadingButton>
            </>
        );
    };

    return (
        <>
            <Form.Group as={Col}>
                <Form.Label>
                    Domain Name
                </Form.Label>
                <Form.Control placeholder="Domain Name" disabled={!edit} value={localDomain.domainName} onChange={handleDomainNameChange} />
                <ErrorComponent path="domainName" errors={localErrors} />
            </Form.Group>
            <Col>
                {buildDomainOptions()}
            </Col>
        </>
    );
};

export default DomainComponent;
