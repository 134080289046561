import React from 'react';
import { ComponentError } from '../types/misc';
import ErrorBanner from './ErrorBanner';

interface TopLevelErrorsProps {
    errors: ComponentError[],
    onCloseError: (uiId: string) => void
}

/**
 * This component is used to render what are called "top level errors".
 * If a component error has a path that is an empty string (''),
 * the component will render them as error banners.
 * When the user clicks close, the onCloseError function will be called
 * and the uiId of the error component will be passed in the function call.
 * This allows parent components to filter out the error message.
 */
const TopLevelErrors = ({ errors, onCloseError }: TopLevelErrorsProps) => {
    const topLevelErrors = errors.filter(({ path }) => path === '');

    const buildTopLevelErrors = () => {
        return topLevelErrors.map(({ message, uiId }) => {
            if (uiId === undefined) {
                console.log('Received a top level error with no uiId');
                return null;
            }

            return (
                <ErrorBanner message={message} key={uiId} onClose={() => onCloseError(uiId)} />
            );
        });
    };

    return (
        <>
            {buildTopLevelErrors()}
        </>
    );
};

export default TopLevelErrors;
