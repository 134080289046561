import React from 'react';
import { useHistory, useParams } from 'react-router';
import Loading from '../../util/Loading';
import StartPage from './pages/StartPage';
import OriginsPage from './pages/OriginsPage';
import DomainAndCertPage from './pages/DomainAndCertPage';
import SummaryPage from './pages/SummaryPage';
import { useDistribution } from './DistributionProvider';
import FormNavigation from './FormNavigation';
import PermissionsPage from './pages/PermissionsPage';
import { PermissionType } from '../../types/permissions';
import { PageHash, FormParams, PageTypes } from '../../types/form';

const pageDisplay: PageHash = {
    [PageTypes.main]: {
        pageTitle: 'Manage Base Distribution',
        pageName: 'Base Distribution'
    },
    [PageTypes.origins]: {
        pageTitle: 'Manage Origins',
        pageName: 'Origins'
    },
    [PageTypes.domains]: {
        pageTitle: 'Manage Domains',
        pageName: 'Domains'
    },
    [PageTypes.permissions]: {
        pageTitle: 'Manage Permissions',
        pageName: 'Permissions'
    },
    [PageTypes.summary]: {
        pageTitle: 'Summary',
        pageName: 'Summary'
    }
};
Object.freeze(pageDisplay);

const pageDisplayReadOnly: PageHash = {
    [PageTypes.permissions]: {
        pageTitle: 'View Permissions',
        pageName: 'Permissions'
    },
    [PageTypes.summary]: {
        pageTitle: 'Summary',
        pageName: 'Summary'
    }
};
Object.freeze(pageDisplayReadOnly);

const readOnlyPages = [PageTypes.permissions, PageTypes.summary];

// DistributionForm is responsible for managing the navigation throughout the form
// and provide validation checks as the user moves to various pages in the form
const DistributionForm = () => {
    const history = useHistory();
    const { distributionName, page } = useParams<FormParams>();
    const { checkDistribution, userPermission, refreshDistribution } = useDistribution();

    const changePage = (newPage: PageTypes) => {
        if (distributionName) {
            history.push(`/distributions/${distributionName}/page/${newPage}`);
        } else {
            history.push(`/distributions/page/${newPage}`);
        }
    };

    const forwardToOriginsPage = async () => {
        refreshDistribution();
        changePage(PageTypes.origins);
    };

    const forwardToDomainsPage = async () => {
        if (await checkDistribution()) {
            refreshDistribution();
            changePage(PageTypes.domains);
        }
    };

    const backAPage = async () => {
        if (await checkDistribution()) {
            refreshDistribution();
            history.goBack();
        }
    };

    const forwardToSummaryPage = async () => {
        if (await checkDistribution()) {
            refreshDistribution();
            changePage(PageTypes.summary);
        }
    };

    const forwardToPermissionsPage = async () => {
        if (await checkDistribution()) {
            refreshDistribution();
            changePage(PageTypes.permissions);
        }
    };

    const renderCorrectPage = () => {
        switch (page) {
            case (PageTypes.main): {
                return (
                    <StartPage
                        onForward={forwardToOriginsPage}
                    />
                );
            }
            case (PageTypes.origins): {
                return (
                    <OriginsPage
                        onBack={backAPage}
                        onForward={forwardToDomainsPage}
                    />
                );
            }
            case (PageTypes.domains): {
                return (
                    <DomainAndCertPage
                        onBack={backAPage}
                        onForward={forwardToPermissionsPage}
                    />
                );
            }
            case (PageTypes.summary): {
                return (
                    <SummaryPage />
                );
            }
            case (PageTypes.permissions): {
                return (
                    <PermissionsPage
                        onForward={forwardToSummaryPage}
                    />
                );
            }
            default: {
                return (
                    <Loading />
                );
            }
        }
    };

    if (!userPermission) {
        return (
            <p>You do not have access to this distribution</p>
        );
    }

    if (!userPermission.includes(PermissionType.write) && !readOnlyPages.includes(page)) {
        return (
            <>
                <FormNavigation pages={pageDisplayReadOnly} currentPage={page} changePage={changePage} />
                <p>You only have read access to this distribution. Please change the page</p>
            </>
        );
    }

    return (
        <>
            <FormNavigation pages={userPermission.includes(PermissionType.write) ? pageDisplay : pageDisplayReadOnly} currentPage={page} changePage={changePage} />
            {renderCorrectPage()}
        </>
    );
};

export default DistributionForm;
