import React, { useState } from 'react';
import { Row, Col, Form, FormGroup } from 'react-bootstrap';
import { nanoid } from 'nanoid';
import { cloneDeep } from 'lodash';
import OriginCard from '../../../components/OriginCard';
import TopLevelErrors from '../../../components/TopLevelErrors';
import { ComponentError } from '../../../types/misc';
import { createEmptyOriginWithNoBehaviors, createEmptyBehavior } from '../../../util/createEmptyObjects';
import ErrorComponent from '../../../util/Error';
import LoadingButton from '../../../util/LoadingButton';
import { DistributionActionTypes, useDistribution } from '../DistributionProvider';
import createOrigin from '../../../util/api/createOrigin';
import styles from './OriginsPage.module.css';
import { useAuthentication } from '../../../authentication/Authentication';
import { Origin } from '../../../types/distribution';
import deleteBehavior from '../../../util/api/deleteBehavior';
import createBehavior from '../../../util/api/createBehavior';

interface OriginsPageProps {
    onBack: Function
    onForward: Function
}

const OriginsPage = ({ onBack, onForward }: OriginsPageProps) => {
    const [newOrigin, setNewOrigin] = useState(createEmptyOriginWithNoBehaviors());
    const [updatingDefaultOriginState, setUpdatingDefaultOriginState] = useState(false);
    const [localErrors, setLocalErrors] = useState<ComponentError[]>([]);
    const { distribution, getDistributionName, dispatch, refreshDistribution } = useDistribution();
    const { token } = useAuthentication();

    const setError = (message: string) => {
        const updateErrors = localErrors.find((x) => (x.message === message)) ? localErrors : [
            ...localErrors,
            {
                path: '',
                message,
                uiId: nanoid()
            }
        ];
        setLocalErrors(updateErrors);
    };

    const handleOnChange = (event: React.ChangeEvent<any>) => {
        const path = event.target.id;
        const value = event.target.value;

        setNewOrigin({
            ...newOrigin,
            [path]: value
        });
    };

    const handleNextPage = async () => {
        const { origins } = distribution;
        if (origins.length === 0) {
            setError('A distribution must have one or more origins');
            return;
        }

        const doesEveryOriginHaveABehavior = origins.every(({ behaviors }) => behaviors.length > 0);
        if (!doesEveryOriginHaveABehavior) {
            setError('Each origin must have one or more behaviors');
            return;
        }

        await onForward();
    };

    const addOrigin = async () => {
        try {
            const originToAdd = cloneDeep(newOrigin);
            if (distribution.origins.length > 25) {
                setError('Cannot add new origin. Reached origin limit of 25.');
                return;
            }
            if (distribution.origins.length === 0) {
                originToAdd.behaviors = [createEmptyBehavior(true, distribution.assetInsightId)];
            }

            const response = await createOrigin(token, getDistributionName(), originToAdd);

            if (!response.success) {
                setLocalErrors(response.errors);
                return;
            }

            const updateAction = {
                type: DistributionActionTypes.UPDATE,
                path: 'origins',
                value: [...distribution.origins, response.origin]
            };
            dispatch(updateAction);
            setNewOrigin(createEmptyOriginWithNoBehaviors());
        } catch (err: any) {
            setError(err.message);
        }
        setLocalErrors([]);
    };

    /**
     * The logic on this function gets a bit messy.
     * In order to make the UI quick and snappy we create the new default behavior, remove the old default behavior
     * and then we update the distribution provider. This happens at millisecond speed.
     * Next, we actually make the API calls to add the new default behavior to the origin and delete
     * the old default behavior if it exist. Finally, we update the distribution again with the
     * new default behavior, since we now have the behavior id.
     * */
    const handleDefaultOrigin = async (originId: string) => {
        if (updatingDefaultOriginState) {
            setError('Currently changing default the origin, please wait');
            return;
        }

        setUpdatingDefaultOriginState(true);
        try {
            const newDefaultOrigin = cloneDeep(distribution.origins.find(({ id }) => id === originId) as Origin);
            const newDefaultBehavior = createEmptyBehavior(true, distribution.assetInsightId);

            newDefaultOrigin.behaviors = [...newDefaultOrigin.behaviors, newDefaultBehavior];

            // remove the old default behavior if one exists
            let oldDefaultBehaviorId: undefined | string;
            const oldDefaultOrigin = distribution.origins.find((origin) => {
                const found = origin.behaviors.find(({ isDefaultBehavior }) => isDefaultBehavior);
                if (found) {
                    oldDefaultBehaviorId = found.id;
                    return true;
                }
                return false;
            });

            let oldDefaultOriginClone: Origin | undefined;
            if (oldDefaultOrigin) {
                oldDefaultOriginClone = cloneDeep(oldDefaultOrigin);
                oldDefaultOriginClone.behaviors = oldDefaultOriginClone.behaviors.filter(({ isDefaultBehavior }) => !isDefaultBehavior);
            }
            const updatedOrigins = distribution.origins.map((origin) => {
                if (origin.id === newDefaultOrigin.id) {
                    return newDefaultOrigin;
                }

                if (oldDefaultOriginClone !== undefined && origin.id === oldDefaultOriginClone.id) {
                    return oldDefaultOriginClone;
                }

                return origin;
            });

            const updateAction = {
                type: DistributionActionTypes.UPDATE,
                path: 'origins',
                value: updatedOrigins
            };
            dispatch(updateAction);

            await createBehavior(token, getDistributionName(), newDefaultOrigin.id as string, newDefaultBehavior);
            if (oldDefaultOrigin) {
                await deleteBehavior(token, getDistributionName(), oldDefaultOrigin.id as string, oldDefaultBehaviorId as string);
            }

            await refreshDistribution();
        } catch (err: any) {
            setError(err.message);
            await refreshDistribution();
        }

        setUpdatingDefaultOriginState(false);
    };

    const buildOriginCards = () => {
        return distribution.origins.map((origin, index) => {
            return (
                <OriginCard
                    origin={origin}
                    key={origin.id || origin.uiId}
                    id={origin.id || origin.uiId}
                    onError={setError}
                    path={`origins[${index}]`}
                    onDefaultOrigin={handleDefaultOrigin}
                />
            );
        });
    };

    const handleClosedError = (uiId: string) => {
        setLocalErrors(localErrors.filter((x) => x.uiId !== uiId));
    };

    return (
        <>
            <TopLevelErrors errors={localErrors} onCloseError={handleClosedError} />
            <Row>
                <Col>
                    <span className="spanBold">Distribution Name:</span><span> {distribution.distributionName}</span>
                </Col>
            </Row>
            <Row>
                <Col>
                    <h5 className="spanBold">Add New Origin</h5>
                </Col>
            </Row>
            <Row>
                <Form>
                    <Row>
                        <FormGroup as={Col}>
                            <Form.Label>
                                Origin Domain Name
                                <span className="required-field"> *</span>
                            </Form.Label>
                            <Form.Control required id="originDomainName" onChange={handleOnChange} placeholder="api.thomsonreuters.com" value={newOrigin.originDomainName} />
                            <ErrorComponent path="originDomainName" errors={localErrors} />
                        </FormGroup>
                        <FormGroup as={Col}>
                            <Form.Label>
                                Origin Path
                            </Form.Label>
                            <Form.Control required id="originPath" onChange={handleOnChange} placeholder="/test" value={newOrigin.originPath} />
                            <ErrorComponent path="originPath" errors={localErrors} />
                        </FormGroup>
                        <Col md="auto">
                            <LoadingButton className={styles.addButton} onClick={addOrigin}>
                                Add Origin
                            </LoadingButton>
                        </Col>
                    </Row>
                </Form>
            </Row>
            <br />
            <Row>
                <Col>
                    <h5 className={styles.spanBold}>Created Origins</h5>
                </Col>
            </Row>
            {buildOriginCards()}
            <Row style={{ marginTop: '10px' }}>
                <Col className="text-left" />
                <Col className="col-auto">
                    <LoadingButton onClick={onBack} style={{ marginRight: '5px' }}>Back</LoadingButton>
                    <LoadingButton onClick={handleNextPage}>Next Page</LoadingButton>
                </Col>
            </Row>
        </>
    );
};

export default OriginsPage;
