import React, { useState } from 'react';
import { Row, Form, Col, Button, Modal } from 'react-bootstrap';
import { cloneDeep } from 'lodash';
import { FiEdit } from 'react-icons/fi';
import { nanoid } from 'nanoid';

import ErrorComponent from '../util/Error';
import updateOrigin from '../util/api/updateOrigin';
import { DistributionActionTypes, useDistribution } from '../distributions/form/DistributionProvider';
import { useAuthentication } from '../authentication/Authentication';
import { CustomHeader, Origin } from '../types/distribution';
import { ComponentError } from '../types/misc';
import styles from './OriginCard.module.css';
import LoadingButton from '../util/LoadingButton';

interface CustomHeadersProps {
    customHeaders: CustomHeader[] | undefined
    origin: Origin
    path: string
}

const CustomHeadersModal = ({ customHeaders, origin, path }: CustomHeadersProps) => {
    const { dispatch, getDistributionName } = useDistribution();
    const { token } = useAuthentication();
    const emptyCustomHeaders = { name: '', value: '', uiId: '' } as CustomHeader;
    // initialize with one empty field for user to enter custom field
    const [localCustomHeaders, setlocalCustomHeaders] = useState<CustomHeader[]>(cloneDeep(
        (customHeaders === undefined ? [] : customHeaders).concat([emptyCustomHeaders]).map((customHeader) => {
            return {
                ...customHeader, uiId: nanoid()
            };
        })
    ));
    const [localErrors, setLocalErrors] = useState<ComponentError[]>([]);

    const [show, setShow] = useState(false);

    const handleClose = () => {
        setShow(false);
    };
    const handleShow = () => setShow(true);
    const distributionName = getDistributionName();
    const updateCustomHeaderName = (event: React.ChangeEvent<any>) => {
        const newLocalCustomHeaders = localCustomHeaders.map((customHeader) => {
            if (customHeader.uiId === event.target.id) {
                return {
                    ...customHeader,
                    name: event.target.value
                };
            }
            return customHeader;
        });
        setlocalCustomHeaders(newLocalCustomHeaders);
    };

    const updateCustomHeaderValue = (event: React.ChangeEvent<any>) => {
        const newLocalCustomHeaders = localCustomHeaders.map((customHeader) => {
            if (customHeader.uiId === event.target.id) {
                return {
                    ...customHeader,
                    value: event.target.value
                };
            }
            return customHeader;
        });
        setlocalCustomHeaders(newLocalCustomHeaders);
    };

    const updateCustomHeaders = async (newCustomHeaders: CustomHeader[]) : Promise<Boolean> => {
        const newOrigin = {
            ...origin,
            customHeaders: newCustomHeaders
        };
        const response = await updateOrigin(token, getDistributionName(), newOrigin, true);
        if (!response.success) {
            response.errors[0].path = `${path}.customHeadersError`;
            const newError = {
                path: response.errors[0].path,
                message: response.errors[0].message
            };
            setLocalErrors([newError]);
            return false;
        }
        dispatch({
            type: DistributionActionTypes.UPDATE,
            path,
            value: newCustomHeaders.filter((customHeader) => (customHeader.name !== '' && customHeader.value !== ''))
        });
        return true;
    };

    const saveButton = async () => {
        setLocalErrors([]);
        const res = await updateCustomHeaders(localCustomHeaders);
        if (res) {
            handleClose();
        }
    };

    const deleteButton = async (uiId: string) => {
        const newCustomHeaders = localCustomHeaders.filter((x) => x.uiId !== uiId);
        setlocalCustomHeaders(newCustomHeaders);
        await updateCustomHeaders(newCustomHeaders);
        dispatch({
            type: DistributionActionTypes.DELETE,
            path,
            id: uiId
        });
    };

    const addHeader = () => {
        setLocalErrors([]);

        if (localCustomHeaders.length >= 10) {
            setLocalErrors([{
                path: `${path}.customHeadersError`,
                message: 'Maximum number of custom headers that you can configure CloudFront to add to origin requests is 10. Please consult ADN devOps team to send custom request if you need more'
            }]);
            return;
        }
        const newCustomHeaders = cloneDeep<CustomHeader[]>(localCustomHeaders);
        newCustomHeaders.push({
            uiId: nanoid(),
            name: '',
            value: ''
        });
        setlocalCustomHeaders(newCustomHeaders);
    };

    const buildCustomHeadersData = () => {
        return localCustomHeaders.map((customHeader, index) => {
            return (
                <>
                    <Row key={`${customHeader.uiId}.${index}.1`}>
                        <Col>
                            <span>Header Name</span>
                        </Col>
                        <Col>
                            <span>Header Value</span>
                        </Col>
                        <Col />
                    </Row>
                    <Row key={`${customHeader.uiId}.${index}.2`}>
                        <Form.Group as={Col}>
                            <Form.Control required type="text" key={`${customHeader.uiId}.${index}.name`} id={customHeader.uiId} onChange={updateCustomHeaderName} placeholder="Custom Header Name" value={customHeader.name} />
                            <ErrorComponent path={`${path}.${customHeader.uiId}.name`} errors={localErrors} />
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Control required type="text" key={`${customHeader.uiId}.${index}.value`} id={customHeader.uiId} onChange={updateCustomHeaderValue} placeholder="Custom Header Value" value={customHeader.value} />
                            <ErrorComponent path={`${path}.${customHeader.uiId}.value`} errors={localErrors} />
                        </Form.Group>
                        <Form.Group as={Col}>
                            <LoadingButton variant="warning" onClick={() => deleteButton(customHeader.uiId)} key={`${customHeader.uiId}.${index}`}>Delete</LoadingButton>
                        </Form.Group>
                    </Row>
                </>
            );
        });
    };

    return (
        <>
            <div className={styles.addEditDiv} key="customHeader.div">
                <Button className={styles.addEditButton} variant="link" onClick={handleShow}>
                    <FiEdit className={styles.addEditIcon} />
                    Custom Headers
                </Button>
            </div>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Add/Edit Custom Headers</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row key="customHeaderInfo.distributionName">
                        <Col>
                            <span className="documentation">Distribution Name: {distributionName}</span>
                        </Col>
                    </Row>
                    <Row key="customHeaderInfo.OriginName">
                        <Col>
                            <span className="documentation">Origin Name: {origin.originDomainName}</span>
                        </Col>
                    </Row>
                    <Row>
                        <Col />
                        <Col />
                        <Col>
                            <Button variant="primary" onClick={addHeader}>+ Add Header</Button>
                        </Col>
                    </Row>
                    <ErrorComponent path={`${path}.customHeadersError`} errors={localErrors} />
                    {buildCustomHeadersData()}
                </Modal.Body>
                <Modal.Footer>
                    <Button key="customHeaders.closeButton" variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <LoadingButton key="customHeaders.saveButton" variant="primary" onClick={saveButton}>
                        Save Changes
                    </LoadingButton>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default CustomHeadersModal;
