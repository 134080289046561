import { nanoid } from 'nanoid';
import fetch from 'node-fetch';
import { Origin, CustomHeader } from '../../types/distribution';
import { ComponentError, JoiErrorResponse } from '../../types/misc';
import emptyStringToUndefined from '../emptyStringToUndefined';

interface UpdateOriginResponse {
    success: boolean,
    origin?: Origin,
    customHeaders?: CustomHeader[],
    errors: ComponentError[]
}

const updateOrigin = async (token: string, distributionName: string, origin: Origin, ifUpdateCustomHeaders: boolean = false): Promise<UpdateOriginResponse> => {
    const response = await fetch(`${process.env.REACT_APP_ADN_MANAGEMENT_BASE_URL}/v1/distributions/${distributionName}/origins/${origin.id}`, {
        method: 'PUT',
        headers: {
            Authorization: `Bearer ${token}`,
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(emptyStringToUndefined(origin))
    });

    if (response.status === 400) {
        const error = (await response.json()) as JoiErrorResponse;

        // not a joi error
        if (!error.validation || !error.validation.errors) {
            return {
                success: false,
                origin,
                errors: [{
                    message: error.message,
                    path: '',
                    uiId: nanoid()
                }]
            };
        }

        return {
            success: false,
            errors: error.validation.errors
        };
    }

    if (!response.ok) {
        throw new Error(`Unexpected error when updating origin. Got status code ${response.status}`);
    }

    const updatedOrigin = (await response.json()) as Origin;
    if (ifUpdateCustomHeaders) {
        return {
            success: true,
            customHeaders: updatedOrigin.customHeaders,
            errors: []
        };
    }
    return {
        success: true,
        origin: updatedOrigin,
        errors: []
    };
};

export default updateOrigin;
