import React from 'react';
import { Row, Col } from 'react-bootstrap';
import LoadingButton from '../../../util/LoadingButton';
import { useDistribution } from '../DistributionProvider';
import Domains from '../Domains';
import CertificateArnCard from '../../../components/CertificateArnCard';

interface DomainAndCertPageProps {
    onBack: Function
    onForward: Function
}

const DomainAndCertPage = ({ onBack, onForward }: DomainAndCertPageProps) => {
    const { distribution } = useDistribution();

    return (
        <>
            <Row>
                <Col>
                    <span className="spanBold">Distribution Name:</span><span> {distribution.distributionName}</span>
                </Col>
            </Row>
            <Row>
                <Col>
                    <h5 className="spanBold">Manage Domains</h5>
                </Col>
            </Row>
            <Domains domains={distribution.domains || []} path="domains" />
            <br />
            <Row>
                <Col>
                    <h5 className="spanBold">Manage Certificate</h5>
                </Col>
            </Row>
            <Row>
                <CertificateArnCard certificateArn={distribution.certificateArn || ''} />
            </Row>
            <Row style={{ marginTop: '10px' }}>
                <Col className="text-left" />
                <Col className="col-auto">
                    <LoadingButton style={{ marginRight: '5px' }} onClick={onBack}>Back</LoadingButton>
                    <LoadingButton onClick={onForward}>Next</LoadingButton>
                </Col>
            </Row>
        </>
    );
};

export default DomainAndCertPage;
