import React, { useState } from 'react';
import { Row, Col, FormGroup, Form } from 'react-bootstrap';
import { nanoid } from 'nanoid';
import { createEmptyDomain } from '../../util/createEmptyObjects';
import DomainCard from '../../components/DomainCard';
import { DistributionActionTypes, useDistribution } from './DistributionProvider';
import { Domain } from '../../types/distribution';
import ErrorComponent from '../../util/Error';
import styles from './Domains.module.css';
import LoadingButton from '../../util/LoadingButton';
import createDomain from '../../util/api/createDomain';
import { useAuthentication } from '../../authentication/Authentication';
import { ComponentError } from '../../types/misc';
import TopLevelErrors from '../../components/TopLevelErrors';

interface DomainsProps {
    domains: Domain[]
    path: string
}

const Domains = ({ domains, path }: DomainsProps) => {
    const [newDomain, setNewDomain] = useState<Domain>(createEmptyDomain());
    const [localErrors, setLocalErrors] = useState<ComponentError[]>([]);
    const { dispatch, getDistributionName } = useDistribution();
    const { token } = useAuthentication();

    const addNewDomain = async () => {
        setLocalErrors([]);

        const response = await createDomain(token, getDistributionName(), newDomain);

        if (response.success) {
            const newDomains = [...domains, response.domain];

            const updateAction = {
                type: DistributionActionTypes.UPDATE,
                path: 'domains',
                value: newDomains
            };
            dispatch(updateAction);

            setNewDomain(createEmptyDomain());
        } else {
            setLocalErrors(response.errors);
        }
    };

    const handleDomainNameChange = (event: React.ChangeEvent<any>) => {
        setNewDomain({
            ...newDomain,
            domainName: event.target.value
        });
    };

    const handleDomainError = (message: string) => {
        setLocalErrors([
            ...localErrors,
            {
                path: '',
                message,
                uiId: nanoid()
            }
        ]);
    };

    const buildDomainsList = () => {
        return domains.map((domain, domainIndex) => {
            return (
                <Row key={domain.id || domain.uiId}>
                    <DomainCard
                        domain={domain}
                        path={`domains[${domainIndex}]`}
                        onError={handleDomainError}
                    />
                    <br />
                </Row>
            );
        });
    };

    const handleClosedError = (uiId: string) => {
        setLocalErrors(localErrors.filter((x) => x.uiId !== uiId));
    };

    return (
        <>
            <TopLevelErrors errors={localErrors} onCloseError={handleClosedError} />
            <Row>
                <FormGroup as={Col}>
                    <Form.Label>
                        Domain Name
                    </Form.Label>
                    <Form.Control placeholder="google.com" id={path} value={newDomain.domainName} onChange={handleDomainNameChange} />
                    <ErrorComponent path="domainName" errors={localErrors} />
                </FormGroup>
                <Col>
                    <LoadingButton className={styles.addButton} onClick={addNewDomain}>Add Domain</LoadingButton>
                </Col>
            </Row>
            {buildDomainsList()}
        </>
    );
};

export default Domains;
