import { nanoid } from 'nanoid';
import fetch from 'node-fetch';
import { Domain } from '../../types/distribution';
import { ComponentError, JoiErrorResponse } from '../../types/misc';
import emptyStringToUndefined from '../emptyStringToUndefined';

interface CreateDomainResponse {
    success: boolean,
    domain?: Domain,
    errors: ComponentError[]
}

const createOrigin = async (token: string, distributionName: string, domain: Domain): Promise<CreateDomainResponse> => {
    const response = await fetch(`${process.env.REACT_APP_ADN_MANAGEMENT_BASE_URL}/v1/distributions/${distributionName}/domains`, {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${token}`,
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(emptyStringToUndefined(domain))
    });

    if (response.status === 400) {
        const error = (await response.json());

        // not a joi error
        if (!error.validation) {
            return {
                success: false,
                errors: [{
                    message: error.message,
                    path: '',
                    uiId: nanoid()
                }]
            };
        }

        const joiError = error as JoiErrorResponse;
        return {
            success: false,
            errors: joiError.validation.errors
        };
    }

    if (!response.ok) {
        throw new Error(`Unexpected error when creating a domain. Got status code ${response.status}`);
    }

    return {
        success: true,
        domain: await response.json(),
        errors: []
    };
};

export default createOrigin;
