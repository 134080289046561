import React, { useState } from 'react';
import { nanoid } from 'nanoid';
import { Container, Form, Alert } from 'react-bootstrap';
import { FaFileUpload } from 'react-icons/fa';
import YAML from 'yaml';
import Button from '../components/Button';
import './OpenApiDocument.css';
import UploadErrors from './UploadErrors';
import uploadOad from '../util/api/uploadOad';
import { useAuthentication } from '../authentication/Authentication';
import Loading from '../util/Loading';

const validFileEndings = [
    '.json',
    '.yaml',
    '.yml'
];

const OpenApiDocument = () => {
    const [uploadKey, setUploadKey] = useState(nanoid());
    const [oad, setOad] = useState(undefined);
    const [errors, setErrors] = useState([]);
    const [isUploading, setIsUploading] = useState(false);
    const [successMessage, setSuccessMessage] = useState(null);
    const { token } = useAuthentication();

    const checkValidFileExtension = (file) => {
        return validFileEndings.find((x) => file.name.endsWith(x));
    };

    const clearFile = () => {
        setOad(undefined);

        // We can't control the value for a file select html element.
        // Instead we cause a new select element to be rendered by using a key when we want to clear the selection
        setUploadKey(nanoid());
    };

    const handleError = (message) => {
        setErrors([{
            path: '',
            message
        }]);
        clearFile();
    };

    const clearMessages = () => {
        setErrors([]);
        setSuccessMessage(null);
    };

    const handleJsonFile = (result) => {
        try {
            const openApi = JSON.parse(result);
            setOad(openApi);
        } catch (err) {
            handleError('Please select a valid JSON file');
        }
    };

    const handleYamlFile = (result) => {
        try {
            const openApi = YAML.parse(result);
            setOad(openApi);
        } catch (err) {
            handleError('Please select a valid YAML file');
        }
    };

    const handleFileUpload = (event) => {
        clearMessages();

        if (event.target.files.length === 0) {
            setOad(undefined);
            return;
        }

        const file = event.target.files[0];
        if (!checkValidFileExtension(file)) {
            handleError('File must end in .json, .yml or .yaml');
            return;
        }

        const fileReader = new FileReader();
        fileReader.readAsText(file);

        fileReader.onload = () => {
            const result = fileReader.result;
            if (file.name.endsWith('.json')) {
                handleJsonFile(result);
            } else {
                handleYamlFile(result);
            }
        };

        fileReader.onerror = () => {
            handleError('Reading the file failed for an unknown reason. Please try again');
            console.log(fileReader.error);
        };
    };

    const uploadDocument = async () => {
        clearMessages();
        setIsUploading(true);

        const result = await uploadOad(token, oad);

        if (result.status === 200) {
            setSuccessMessage('OpenAPI document uploaded successfully. Your API information will be available on our ');
            clearFile();
        } else if (result.status === 400) {
            setErrors(result.errors);
            clearFile();
        } else {
            handleError('File upload failed for an unknown reason. Please try again');
        }

        setIsUploading(false);
    };

    const displaySuccessMessage = () => {
        if (!successMessage) {
            return null;
        }

        return (
            <Alert variant="success">
                {successMessage}
                <Alert.Link href="https://app.powerbi.com/groups/me/reports/6b3f9db6-5c7e-461d-9b69-a5c7cd11c14a/ReportSectiondb185310b96f8e0060a9">
                    API Dashboard
                </Alert.Link> within 4 to 8 hours.
            </Alert>
        );
    };

    return (
        <Form as={Container} className="adnFileUpload">
            <section>
                <Form.Label>Upload an OpenAPI Document (.json, .yml, .yaml)</Form.Label>
                <Form.Control
                    type="file"
                    onChange={handleFileUpload}
                    accept=".yaml,.yml,.json"
                    key={uploadKey}
                />

                <Button className="adnUploadButton" onClick={uploadDocument} type="button" disabled={oad === undefined} variant={oad === undefined ? 'secondary' : 'primary'}>
                    {isUploading ? <Loading /> : <FaFileUpload />}
                </Button>

                <UploadErrors errors={errors} />
                {displaySuccessMessage()}
            </section>
        </Form>
    );
};

export default OpenApiDocument;
