import React, { useState } from 'react';
import { Alert } from 'react-bootstrap';

interface ErrorBannerProps {
    message: string,
    onClose?: Function;
}

const ErrorBanner = ({ message, onClose }: ErrorBannerProps) => {
    const [show, setShow] = useState(true);

    if (!show) {
        return null;
    }

    const handleOnClose = () => {
        setShow(false);
        if (onClose) {
            onClose();
        }
    };

    return (
        <Alert variant="danger" onClose={handleOnClose} dismissible>
            {message}
        </Alert>
    );
};

ErrorBanner.defaultProps = {
    onClose: undefined
};

export default ErrorBanner;
