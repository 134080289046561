import React, { useState } from 'react';
import { Form, Col } from 'react-bootstrap';
import { FiEdit } from 'react-icons/fi';
import updateCertificateArn from '../util/api/updateCertificateArn';
import ErrorComponent from '../util/Error';
import LoadingButton from '../util/LoadingButton';
import { useAuthentication } from '../authentication/Authentication';
import { DistributionActionTypes, useDistribution } from '../distributions/form/DistributionProvider';
import { ComponentError } from '../types/misc';

interface CertificateArnCardProps {
    certificateArn: string
}

const CertificateArn = ({ certificateArn }: CertificateArnCardProps) => {
    const [localCertificateArn, setLocalCertificateArn] = useState<string>(certificateArn);
    const [edit, setEdit] = useState(localCertificateArn === '');
    const [localErrors, setLocalErrors] = useState<ComponentError[]>([]);
    const { dispatch, getDistributionName } = useDistribution();
    const { token } = useAuthentication();

    const handleCertificateArnChange = (event: React.ChangeEvent<any>) => {
        setLocalCertificateArn(event.target.value);
    };

    const updateCertificateArnEvent = async () => {
        setLocalErrors([]);
        const response = await updateCertificateArn(token, getDistributionName(), localCertificateArn);

        if (response.success) {
            if (response.certificateArn !== undefined && response.certificateArn !== '') {
                setEdit(false);
            }

            const updateAction = {
                type: DistributionActionTypes.UPDATE,
                path: 'certificateArn',
                value: response.certificateArn
            };
            dispatch(updateAction);
        } else {
            setLocalErrors(response.errors);
        }
    };

    const clearCertificateArnEvent = async () => {
        setLocalErrors([]);
        const response = await updateCertificateArn(token, getDistributionName(), '');

        if (response.success) {
            setLocalCertificateArn('');
            setEdit(true);
            const updateAction = {
                type: DistributionActionTypes.UPDATE,
                path: 'certificateArn',
                value: undefined
            };
            dispatch(updateAction);
        } else {
            setLocalErrors(response.errors);
        }
    };

    const enableEdit = () => {
        setEdit(true);
    };

    const buildCertificateOptions = () => {
        const buttonKey = 'certButton';
        if (edit) {
            return (
                <LoadingButton key={buttonKey} variant="outline-primary" className="inline-form-button" onClick={updateCertificateArnEvent}>
                    Update Certificate
                </LoadingButton>
            );
        }

        return (
            <>
                <FiEdit size={38} style={{ marginRight: '5px' }} onClick={enableEdit} className="inline-form-button" />
                <LoadingButton key={buttonKey} variant="outline-primary" className="inline-form-button" onClick={clearCertificateArnEvent}>
                    Clear
                </LoadingButton>
            </>
        );
    };

    return (
        <>
            <Form.Group as={Col}>
                <Form.Label>
                    Certificate Arn
                </Form.Label>
                <Form.Control placeholder="arn:aws:acm:us-east-1:616080615447:certificate/a2bb897d-0fc4-4617-91b0-4df04c2b5790" disabled={!edit} value={localCertificateArn} onChange={handleCertificateArnChange} />
                <ErrorComponent path="certificateArn" errors={localErrors} />
            </Form.Group>
            <Col>
                {buildCertificateOptions()}
            </Col>
        </>
    );
};

export default CertificateArn;
