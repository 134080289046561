import React, { useState } from 'react';
import { Row, Form, Col, Button, Modal } from 'react-bootstrap';
import { FiEdit } from 'react-icons/fi';

import { nanoid } from 'nanoid';
import { DistributionActionTypes, useDistribution } from '../../distributions/form/DistributionProvider';
import { useAuthentication } from '../../authentication/Authentication';
import { Behavior, Origin } from '../../types/distribution';
import createBehavior from '../../util/api/createBehavior';
import { ComponentError } from '../../types/misc';
import styles from '../OriginCard.module.css';
import LoadingButton from '../../util/LoadingButton';
import TopLevelErrors from '../TopLevelErrors';
import { createEmptyBehavior } from '../../util/createEmptyObjects';
import BehaviorCard from './BehaviorCard';
import ErrorComponent from '../../util/Error';

interface BehaviorProps {
    behaviors: Behavior[]
    origin: Origin
    path: string
}

const BehaviorModal = ({ behaviors, origin, path }: BehaviorProps) => {
    const { dispatch, getDistributionName, distribution } = useDistribution();
    const { token } = useAuthentication();
    const [newBehavior, setNewBehavior] = useState<Behavior>(createEmptyBehavior(false, distribution.assetInsightId));
    const [localErrors, setLocalErrors] = useState<ComponentError[]>([]);
    const [updatingBehaviors, setUpdatingBehaviors] = useState<string[]>([]);

    const [show, setShow] = useState(false);

    const handleShow = () => setShow(true);

    const handleClosedError = (uiId: string) => {
        setLocalErrors(localErrors.filter((x) => x.uiId !== uiId));
    };

    const setError = (message: string) => {
        setLocalErrors([
            {
                path: '',
                message,
                uiId: nanoid()
            }
        ]);
    };

    const handleClose = () => {
        if (updatingBehaviors.length > 0) {
            setError('Please save all changes on behaviors before exiting.');
            return;
        }

        setShow(false);
    };

    const handleOnChange = (event: React.ChangeEvent<any>) => {
        const field = event.target.id;
        const value = event.target.value;

        setNewBehavior({
            ...newBehavior,
            [field]: value
        });
    };

    const saveButton = async () => {
        setLocalErrors([]);
        if (!newBehavior.uriPattern) {
            setLocalErrors([
                {
                    path: 'uriPattern',
                    message: 'Behavior path cannot be blank.',
                    uiId: nanoid()
                }
            ]);
        } else if (newBehavior.uriPattern === '*') {
            setLocalErrors([
                {
                    path: 'uriPattern',
                    message: 'Behavior path "*" is reserved for the default origin.',
                    uiId: nanoid()
                }
            ]);
        } else {
            const response = await createBehavior(token, getDistributionName(), origin.id as string, newBehavior);
            if (!response.success) {
                setLocalErrors(response.errors);
            } else {
                const createdBehavior = response.behavior;
                dispatch({
                    type: DistributionActionTypes.UPDATE,
                    path,
                    value: [...behaviors,
                        createdBehavior]
                });
                setNewBehavior(createEmptyBehavior(false, distribution.assetInsightId));
            }
        }
    };

    const handleEditingBehavior = async (behaviorId: string, editing: boolean) => {
        if (editing) {
            setUpdatingBehaviors([...updatingBehaviors, behaviorId]);
        } else {
            setUpdatingBehaviors(updatingBehaviors.filter((id) => id !== behaviorId));
        }
        console.log(updatingBehaviors);
    };

    const buildBehaviorCards = () => {
        const behaviorComponents = origin.behaviors.map((behavior, index) => {
            const behaviorPath = `${path}[${index}]`;
            return (
                <Row>
                    <Col>
                        <BehaviorCard
                            key={behavior.id}
                            behavior={behavior}
                            origin={origin}
                            path={behaviorPath}
                            onEditing={handleEditingBehavior}
                        />
                    </Col>
                </Row>
            );
        });

        return behaviorComponents;
    };
    return (
        <>
            <div className={styles.addEditDiv}>
                <Button onClick={handleShow} className={styles.addEditButton} variant="link">
                    <FiEdit className={styles.addEditIcon} />
                    Behaviors
                </Button>
            </div>

            <Modal size="lg" centered show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Behavior</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <span className="documentation">Origin Domain Name: {origin.originDomainName}</span>
                    </Row>
                    {buildBehaviorCards()}
                    <TopLevelErrors errors={localErrors} onCloseError={handleClosedError} />
                    <Row>
                        <Form.Group as={Col}>
                            <Form.Label>
                                Behavior Path
                                <span className="required-field"> *</span>
                            </Form.Label>
                            <Form.Control id="uriPattern" required value={newBehavior.uriPattern} type="text" placeholder="/api" onChange={handleOnChange} />
                            <ErrorComponent path="uriPattern" errors={localErrors} />
                        </Form.Group>
                        <Col>
                            <LoadingButton onClick={saveButton} className={styles.addBehaviorButton}>Add Behavior</LoadingButton>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default BehaviorModal;
