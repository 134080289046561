import fetch from 'node-fetch';
import { nanoid } from 'nanoid';
import { ComponentError, JoiErrorResponse } from '../../types/misc';

interface UpdateCertificateArnResponse {
    success: boolean
    certificateArn?: string
    errors: ComponentError[]
}

const updateCertificateArn = async (token: string, distributionName: string, certificateArn: string): Promise<UpdateCertificateArnResponse> => {
    const certificateArnBody = certificateArn === '' ? {} : { certificateArn };

    const response = await fetch(`${process.env.REACT_APP_ADN_MANAGEMENT_BASE_URL}/v1/distributions/${distributionName}/certificateArn`, {
        method: 'PUT',
        headers: {
            Authorization: `Bearer ${token}`,
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(certificateArnBody)
    });

    if (response.status === 400) {
        const error = (await response.json()) as JoiErrorResponse;

        // not a joi error
        if (!error.validation) {
            return {
                success: false,
                errors: [{
                    message: error.message,
                    path: '',
                    uiId: nanoid()
                }]
            };
        }

        return {
            success: false,
            errors: error.validation.errors
        };
    }

    if (!response.ok) {
        throw new Error(`Unexpected error when updating the certificate arn. Got status code ${response.status}`);
    }

    const body = await response.json();

    return {
        success: true,
        certificateArn: body.certificateArn,
        errors: []
    };
};

export default updateCertificateArn;
