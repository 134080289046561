import React, { useState } from 'react';
import { cloneDeep } from 'lodash';
import { Row, Col, Form, FormGroup, Container } from 'react-bootstrap';
import { FiEdit } from 'react-icons/fi';
import { Origin, OriginVerify } from '../types/distribution';
import LoadingButton from '../util/LoadingButton';
import updateOrigin from '../util/api/updateOrigin';
import { useDistribution, DistributionDeleteAction, DistributionActionTypes, DistributionUpdateAction } from '../distributions/form/DistributionProvider';
import { useAuthentication } from '../authentication/Authentication';
import { ComponentError } from '../types/misc';
import ErrorComponent from '../util/Error';
import deleteOrigin from '../util/api/deleteOrigin';
import styles from './OriginCard.module.css';
import BehaviorInfoCard from './infoCards/BehaviorInfoCard';
import CustomHeadersInfoCard from './infoCards/CustomHeaderInfoCard';
import OriginVerifyModal from './OriginVerifyModal';
import BehaviorModal from './behaviors/BehaviorModal';
import OriginVerifyInfoCard from './infoCards/OriginVerifyInfoCard';
import CustomHeadersModal from './CustomHeadersModal';

interface OriginCardProps {
    origin: Origin
    onError: Function
    path: string
    id: string
    onDefaultOrigin: (id: string) => void
}

const OriginCard = ({ origin, onError, path, id, onDefaultOrigin }: OriginCardProps) => {
    const [edit, setEdit] = useState(false);
    // make sure to create a new copy of the original object using cloneDeep
    const [localOrigin, setLocalOrigin] = useState(cloneDeep(origin));
    const [localErrors, setLocalErrors] = useState<ComponentError[]>([]);
    const { getDistributionName, dispatch } = useDistribution();
    const { token } = useAuthentication();

    const handleOnChange = (event: React.ChangeEvent<any>) => {
        const field = event.target.id;
        const value = event.target.value;

        setLocalOrigin({
            ...localOrigin,
            [field]: value
        });
    };

    const enableEdit = () => {
        setEdit(true);
    };

    const isDefaultOrigin = () => {
        return origin.behaviors.some((x) => x.isDefaultBehavior);
    };

    const handleDefaultOriginClick = () => {
        if (isDefaultOrigin()) {
            // no-op
            return;
        }

        onDefaultOrigin(id);
    };

    const createEmptyOriginVerify = () => {
        return {
            originType: 'AWS',
            originMetaData: {
                accountId: '',
                region: 'us-east-1'
            }
        } as OriginVerify;
    };

    const updateOriginEvent = async () => {
        setLocalErrors([]);

        try {
            const response = await updateOrigin(token, getDistributionName(), localOrigin);
            if (!response.success) {
                // set errors
                setLocalErrors(response.errors);
                return;
            }

            setLocalOrigin(response.origin as Origin);

            const updateAction: DistributionUpdateAction = {
                type: DistributionActionTypes.UPDATE,
                path,
                value: response.origin
            };
            dispatch(updateAction);
            setEdit(false);
        } catch (err: any) {
            onError(err.message);
        }
    };

    const deleteOriginEvent = async () => {
        if (isDefaultOrigin()) {
            onError('Cannot delete the default origin. Set a new default origin and try again');
            return;
        }

        try {
            await deleteOrigin(token, getDistributionName(), origin.id as string);
            const deleteAction: DistributionDeleteAction = {
                type: DistributionActionTypes.DELETE,
                path: 'origins',
                id: origin.id
            };

            dispatch(deleteAction);
        } catch (err) {
            onError('Failed to delete origin');
        }
    };

    const buildOriginOptions = () => {
        const buttonKey = `${id}-update-remove-button`;
        if (edit) {
            return (
                <LoadingButton key={buttonKey} variant="outline-primary" className="inline-form-button" onClick={updateOriginEvent}>
                    Update Origin
                </LoadingButton>
            );
        }

        return (
            <>
                <FiEdit size={38} style={{ marginRight: '5px' }} onClick={enableEdit} className="inline-form-button" />
                <LoadingButton key={buttonKey} variant="outline-primary" className="inline-form-button" onClick={deleteOriginEvent}>
                    Remove Origin
                </LoadingButton>
            </>
        );
    };

    const buildBehaviorInfoCards = () => {
        const behaviorComponents = origin.behaviors.map((behavior) => {
            return (
                <BehaviorInfoCard behavior={behavior} key={behavior.id} />
            );
        });

        const behaviorRows = [];
        for (let i = 0; i < behaviorComponents.length; i += 2) {
            if (i + 1 === behaviorComponents.length) {
                behaviorRows.push((
                    <Row>
                        <Col>
                            {behaviorComponents[i]}
                        </Col>
                    </Row>
                ));
            } else {
                behaviorRows.push((
                    <Row>
                        <Col sm={6} md={6} xxl={4}>
                            {behaviorComponents[i]}
                        </Col>
                        <Col sm={6} md={6} xxl={4}>
                            {behaviorComponents[i + 1]}
                        </Col>
                        <Col sm={0} md={0} xxl={4} />
                    </Row>
                ));
            }
        }
        return behaviorRows;
    };

    const buildCustomHeaderInfoCards = () => {
        if (!origin.customHeaders) {
            return null;
        }

        const headers = origin.customHeaders.map((customHeader) => {
            return <CustomHeadersInfoCard customHeader={customHeader} key={customHeader.name} />;
        });
        return (
            <div className="infoCardSpacing">
                {headers}
            </div>
        );
    };

    const buildOriginVerifyInfoCard = () => {
        if (!origin.originVerify) {
            return null;
        }

        return (
            <OriginVerifyInfoCard originVerify={origin.originVerify} />
        );
    };

    return (
        <>
            <Form>
                <ErrorComponent path="" errors={localErrors} />
                <Row>
                    <FormGroup as={Col}>
                        <Form.Label>
                            Origin Domain Name
                            <span className="required-field"> *</span>
                        </Form.Label>
                        <Form.Control required id="originDomainName" onChange={handleOnChange} disabled={!edit} value={localOrigin.originDomainName} />
                        <ErrorComponent path="originDomainName" errors={localErrors} />
                    </FormGroup>
                    <FormGroup as={Col}>
                        <Form.Label>
                            Origin Path
                        </Form.Label>
                        <Form.Control required id="originPath" onChange={handleOnChange} disabled={!edit} value={localOrigin.originPath === undefined ? '' : localOrigin.originPath} />
                        <ErrorComponent path="originPath" errors={localErrors} />
                    </FormGroup>
                    <Col md="auto">
                        {buildOriginOptions()}
                    </Col>
                </Row>
            </Form>
            <Container fluid>
                <Row>
                    <Col>
                        <input type="radio" className={styles.radioButton} checked={isDefaultOrigin()} onChange={handleDefaultOriginClick} />
                        <span>Set as default origin</span>
                    </Col>
                </Row>
                <Container fluid>
                    {buildOriginVerifyInfoCard()}
                    {buildCustomHeaderInfoCards()}
                    {buildBehaviorInfoCards()}
                </Container>
                <br />
                <Row>
                    <Col>
                        {/* TODO: Add logic to determine Add or Edit for each span. I.E. no behaviors exist Add, else Edit */}
                        <BehaviorModal
                            key={`${origin.id}-behaviors`}
                            behaviors={origin.behaviors}
                            origin={origin}
                            path={`${path}.behaviors`}
                        />
                        <CustomHeadersModal
                            key={`${origin.id}-custom-headers`}
                            customHeaders={origin.customHeaders}
                            origin={origin}
                            path={`${path.replaceAll('[', '.').replaceAll(']', '')}.customHeaders`}
                        />
                        <OriginVerifyModal
                            key={origin.originVerify ? `${origin.id}-origin-verify` : `${origin.id}-new-origin-verify`}
                            originVerify={origin.originVerify || createEmptyOriginVerify()}
                            originId={id}
                            exists={!!origin.originVerify}
                            path={`${path}.originVerify`}
                        />
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default OriginCard;
