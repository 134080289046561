import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { OriginVerify, OriginTypes, OriginMetaDataAws, OriginMetaDataApigee } from '../../types/distribution';
import styles from './InfoCards.module.css';

interface OriginVerifyInfoCardProps {
    originVerify: OriginVerify
}

const OriginVerifyInfoCard = ({ originVerify }: OriginVerifyInfoCardProps) => {
    if (originVerify.originType === OriginTypes.aws) {
        const originMetaData = originVerify.originMetaData as OriginMetaDataAws;

        return (
            <div className="infoCardSpacing">
                <Row className={styles.rowPadding}>
                    <Col sm={6} md={6} xxl={4}>
                        <span className="spanBold">Origin Type: </span> {originVerify.originType}
                    </Col>
                    <Col sm={6} md={6} xxl={4}>
                        <span className="spanBold">Account Id: </span>{originMetaData.accountId}
                    </Col>
                    <Col sm={0} md={0} xxl={4} />
                </Row>
                <Row>
                    <Col sm={6} md={6} xxl={4}>
                        <span className="spanBold">Region: </span> {originMetaData.region}
                    </Col>
                    <Col sm={6} md={6} xxl={6} />
                </Row>
            </div>
        );
    }
    const originMetaData = originVerify.originMetaData as OriginMetaDataApigee;

    return (
        <div className="infoCardSpacing">
            <Row className={styles.rowPadding}>
                <Col sm={6} md={6} xxl={4}>
                    <span className="spanBold">Origin Type: </span> {originVerify.originType}
                </Col>
                <Col sm={6} md={6} xxl={4}>
                    <span className="spanBold">Organization: </span>{originMetaData.organization}
                </Col>
                <Col sm={0} md={0} xxl={4} />
            </Row>
            <Row>
                <Col sm={6} md={6} xxl={4}>
                    <span className="spanBold">Environment: </span> {originMetaData.environment}
                </Col>
                <Col sm={6} md={6} xxl={4}>
                    <span className="spanBold">Virtual Host: </span>{originMetaData.virtualHost}
                </Col>
                <Col sm={0} md={0} xxl={4} />
            </Row>
        </div>
    );
};

export default OriginVerifyInfoCard;
