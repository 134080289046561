import React from 'react';
import { Row, Form, Col } from 'react-bootstrap';

import ErrorComponent from '../../util/Error';
import { OriginMetaDataAws } from '../../types/distribution';
import { ComponentError } from '../../types/misc';

interface AwsMetaDataProps {
    originMetaData: OriginMetaDataAws
    setOriginMetaData: Function
    path: String
    errors: ComponentError[]
}

const AwsMetaData = ({ originMetaData, setOriginMetaData, path, errors }: AwsMetaDataProps) => {
    const regionList = [
        'us-east-1',
        'us-east-2',
        'us-west-1',
        'us-west-2',
        'ap-east-1',
        'ap-south-1',
        'ap-northeast-3',
        'ap-northeast-2',
        'ap-southeast-1',
        'ap-southeast-2',
        'ap-northeast-1',
        'ca-central-1',
        'eu-central-1',
        'eu-west-1',
        'eu-west-2',
        'eu-west-3',
        'eu-north-1',
        'sa-east-1'
    ];

    const updateOriginVerify = (event: React.ChangeEvent<any>) => {
        setOriginMetaData({
            ...originMetaData,
            [event.target.id]: event.target.value
        });
    };

    return (
        <Row>
            <Form.Group as={Col}>
                <Form.Label>
                    Account ID
                    <span className="required-field"> *</span>
                </Form.Label>
                <Form.Control required id="accountId" onChange={updateOriginVerify} placeholder="Account ID" value={originMetaData.accountId} />
                <ErrorComponent path={`${path}.accountId`} errors={errors} />
            </Form.Group>
            <Form.Group as={Col}>
                <Form.Label>
                    Region
                    <span className="required-field"> *</span>
                </Form.Label>
                <Form.Select id="region" value={originMetaData.region} onChange={updateOriginVerify}>
                    {regionList.map((region) => {
                        return <option key={region} value={region}>{region}</option>;
                    })}
                </Form.Select>
                <ErrorComponent path={`${path}.region`} errors={errors} />
            </Form.Group>
        </Row>
    );
};

export default AwsMetaData;
